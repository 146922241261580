<template>
	<page-header
		name="Members"
		button-name="Invite"
		:on-create-click="openModal"
	/>
	<data-table
		:data-source="dataSource"
		:header-mapping="headerMapping"
		:rpp="10"
		:select-at-row="selectAtRow"
	/>
	<var-style-provider :style-vars="{ '--dialog-width': '420px'}">
		<var-dialog
			v-model:show="showModal"
			confirm-button-text="Invite"
			cancel-button-text="Cancel"
			@before-close="onBeforeClose">
			<template #title>
				Invite Member
			</template>

			<var-form ref="form">
				<var-row :gutter="20">
					<var-col :span="12">
						<var-input
							placeholder="First Name"
							:rules="[v => !!v || 'The member\'s first name cannot be empty']"
							v-model="formData.first_name"
							class="mb-5"
						/>
					</var-col>
					<var-col :span="12">
						<var-input
							placeholder="Last Name"
							:rules="[v => !!v || 'The member\'s last name cannot be empty']"
							v-model="formData.last_name"
							class="mb-5"
						/>
					</var-col>
				</var-row>

				<var-input
					placeholder="Email Address"
					:rules="[v => !!v || 'The member\'s email address cannot be empty']"
					v-model="formData.email"
					class="mb-5"
				/>
			</var-form>
		</var-dialog>
	</var-style-provider>
</template>

<script>
import PageHeader from "../components/PageHeader";
import DataTable from "../components/DataTable";
import {reactive, ref} from "vue";
import request from "../request";
export default {
	name: "Members",
	components: {DataTable, PageHeader},
	setup() {
		const formData = reactive({
			first_name: null,
			last_name: null,
			email: null
		})

		const form = ref(null)

		return {
			form,
			formData
		}
	},
	data() {
		return {
			dataSource: (page, rpp) => new Promise((resolve, reject) => {
				request.get(process.env.VUE_APP_BASE_URL + '/members?page=' + page + '&rpp=' + rpp)
					.then(response => resolve(response))
					.catch(error => reject(error))
			}),
			onBeforeClose: (action, done) => {
				if (action === 'confirm') {
					this.form.validate().then(validated => {
						if (!validated) {
							return
						}

						this.loading = true

						request.post(process.env.VUE_APP_BASE_URL + '/members', this.formData)
							.then(data => {
								this.loading = false
								done()
								this.$router.push({
									name: 'MemberDetails',
									params: {
										id: data.member.id
									}
								})
							})
					})
				} else {
					done()
				}
			},
			headerMapping: {
				first_name: 'First Name',
				last_name: 'Last Name',
				email: 'Email Address',
				status: {
					label: 'Status',
					chip: true,
					type: value => {
						switch (value) {
							case 'Invited':
								return 'warning';
							case 'Active':
								return 'success';
							case 'Inactive':
								return 'danger';
							default:
								return 'default';
						}
					}
				},
			},
			loading: false,
			showModal: false
		}
	},
	methods: {
		selectAtRow(id) {
			this.$router.push({
				name: 'MemberDetails',
				params: {
					id
				}
			})
		},
		openModal() {
			this.showModal = true
		}
	}
}
</script>

<style scoped>

</style>